<template>
    <section class="content">
        <div class="col-md-12">
            <div class="row">
                <div v-for="(row, index) in optRank" v-bind:key="row.id" class="col-md-12">
                    <div class="card">
                        <div class="card-body" style="padding: 0.7rem;">
                            <div class="row"  v-if="row.type_id != 1">
                                <div class="col-4" v-if="index+1 == 1">
                                    <img src="https://img.icons8.com/external-justicon-lineal-color-justicon/60/000000/external-medal-awards-justicon-lineal-color-justicon.png"/>
                                </div>
                                <div class="col-4" v-if="index+1 == 2">
                                    <img src="https://img.icons8.com/external-justicon-lineal-color-justicon/60/000000/external-medal-awards-justicon-lineal-color-justicon-1.png"/>
                                </div>
                                <div class="col-4" v-if="index+1 == 3">
                                    <img src="https://img.icons8.com/external-justicon-lineal-color-justicon/60/000000/external-medal-awards-justicon-lineal-color-justicon-2.png"/>
                                </div>
                                <div class="col-4" v-if="index+1 > 3">
                                    <div class="info-box shadow-none my-1" style="background: none;align-items: center;">
                                        <span class="info-box-icon bg-secondary" style="border-radius:50%; width:45px; height:45px;"><h3 class="my-2">{{index+1}}</h3></span>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <h5 class="text-white">nama siswa</h5>
                                    <h4 class="text-wrap"> {{row.nama_siswa}} </h4>
                                </div>
                                <div class="col-4">
                                    <h5 class="text-center">score</h5>
                                    <h1 class="text-center"> {{row.final_score}} </h1>
                                </div>
                            </div>
                            <div class="row" v-else>
                                <div class="col-3 col-sm-2 col-md-2" style="min-height: 60px;" v-if="index+1 == 1">
                                    <img class=" vertical-center" src="https://img.icons8.com/external-justicon-lineal-color-justicon/60/000000/external-medal-awards-justicon-lineal-color-justicon.png"/>
                                </div>
                                <div class="col-3 col-sm-2 col-md-2" style="min-height: 60px;" v-if="index+1 == 2">
                                    <img class=" vertical-center" src="https://img.icons8.com/external-justicon-lineal-color-justicon/60/000000/external-medal-awards-justicon-lineal-color-justicon-1.png"/>
                                </div>
                                <div class="col-3 col-sm-2 col-md-2" style="min-height: 60px;" v-if="index+1 == 3">
                                    <img class=" vertical-center" src="https://img.icons8.com/external-justicon-lineal-color-justicon/60/000000/external-medal-awards-justicon-lineal-color-justicon-2.png"/>
                                </div>
                                <div class="col-3 col-sm-2 col-md-2" v-if="index+1 > 3">
                                    <div class="info-box shadow-none my-1" style="background: none;align-items: center;">
                                        <span class="info-box-icon bg-secondary" style="border-radius:50%; width:45px; height:45px;"><h3 class="my-2">{{index+1}}</h3></span>
                                    </div>
                                </div>
                                <!-- </div> -->
                                <div class="col-9 col-sm-10 col-md-4">
                                    <h4 class="text-wrap vertical-center"> {{row.nama_siswa}} </h4>
                                </div>
                                <div class="col-12 col-sm-12 col-md-6 row">
                                    <table class="table table-borderless" style="margin-bottom: 0px;">
                                        <tr>
                                            <th style="text-align: center;">TWK</th>
                                            <th style="text-align: center;">TIU</th>
                                            <th style="text-align: center;">TKP</th>
                                            <th style="text-align: center;">Score</th>
                                            <th style="text-align: center;">Status</th>
                                        </tr>
                                        <tr>
                                            <td style="text-align: center;padding: 0px"> {{row.twk}} </td>
                                            <td style="text-align: center;padding: 0px"> {{row.tiu}} </td>
                                            <td style="text-align: center;padding: 0px"> {{row.tkp}} </td>
                                            <td style="text-align: center;padding: 0px"> <h2 class="text-center"> {{row.final_score}} </h2> </td>
                                            <td style="text-align: center;padding: 0px; width:20%"> {{row.lulus}} </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="optRank.length < 1">
            <div class="col-md-12 text-center">
                <h2>BELUM ADA DATA RANKING.</h2>
            </div>
        </div>
    </section>
    <!-- /.content -->
</template>
<script>
import { createTable, authFetch, auth } from '@/libs/hxcore';
import $ from 'jquery';
import datepicker from '@/components/Datepicker';
import Swal from "sweetalert2";
import moment from "moment";

export default {
    name: 'Siswalist',
    data() {
        return {
            errors: [],
            optRank: [],
            filter: {},
            siswa: {},
            method: '',
            roles: '',
            id: '',
            branch: '',
            userid: '',
        }
    },
    components: {
        datepicker
    },
    created: function() {
        this.roles = this.$route.meta.roles;
        console.log('load initial data', this.$route);
        // this.userid = auth.user().id;

        let mapel = this.$route.params.mapel;
        let code = this.$route.params.id;
        this.loadRank(mapel, code);
    },
    methods: {
        loadRank(mapel, code){
            var filter = { mapel_id: mapel, ujian_code: code };
            let data = new URLSearchParams(filter);
            let url = "/siswa/ujian/ranking?" + data;

            authFetch(url)
            .then((res) => {
            if (res.status === 201) {
            } else if (res.status === 400) {
            }
            return res.json();
            })
            .then((js) => {
            this.optRank = js.data;
            });
        },
        handleClick(e) {
        },
    },
    mounted() {
        const e = this.$refs;
        var self = this;
    }
}
</script>
<style type="text/css">
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }

    .vertical-center {
        margin: 0;
        position: absolute;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }
</style>